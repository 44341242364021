import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import * as uuid from "uuid";
import { CustomHttpParams } from "../../helpers/custom-http-params";
import { AESEncryptDecryptService } from "../services/aes-encrypt-decrypt.service";
import { SessionTimerService } from "../services/session-timer.service";

@Injectable()
export class AuthenticationInterceptorService implements HttpInterceptor {
  constructor(private aesEncryptDecryptService: AESEncryptDecryptService, public sessionTimer: SessionTimerService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(req, next));
  }
  private async handleAccess(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    let headers = req.headers;
    let params = req.params;
    try {
      const token = localStorage.getItem("token");
      if (token) {
        this.sessionTimer.resetTimer();
      }
      const ngCaptcha = localStorage.getItem("captcha");
      const solId = uuid.v4();
      const requestUUID = solId + "|" + new Date().getTime();
      if (req.params instanceof CustomHttpParams && req.params.type) {
        switch (req.params.type) {
          case "api-gateway-clients":
            let key = null;
            try {
              key = this.aesEncryptDecryptService.decrypt(environment.api_key.clients.x_api_key);
            } catch (error) {}
            headers = headers.append("x-api-key", key);
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("request-uuid", requestUUID);
            headers = headers.append("Authorization", `Bearer ${token}`);
            break;
          case "api-gateway-vehicles":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("Authorization", `Bearer ${token}`);
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.vehicles.x_api_key),
            );
            headers = headers.append(
              "request-uuid",
              this.aesEncryptDecryptService.decrypt(environment.api_key.vehicles.request_uuid),
            );
            break;
          case "api-gateway-commons":
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.commons.x_api_key),
            );
            params = params.append("ngsw-bypass", "true");
            break;
          case "api-gateway-commons-token":
            headers = headers.append("Authorization", `Bearer ${token}`);
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.commons.x_api_key),
            );
            params = params.append("ngsw-bypass", "true");
            break;
          case "api-gateway-toll-public":
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.tollPublic.x_api_key),
            );
            headers = headers.append("request-uuid", requestUUID);
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("ctype", "backoffice");
            break;
          case "api-gateway-toll-public-token":
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.tollPublic.x_api_key),
            );
            headers = headers.append("request-uuid", requestUUID);
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("Authorization", `Bearer ${token}`);
            break;
          case "api-pn-security-apiKey":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.security.x_api_key),
            );
            headers = headers.append("request-uuid", requestUUID);
            break;
          case "api-pn-security-apiKey-token":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("Authorization", `Bearer ${token}`);
            headers = headers.append("request-uuid", requestUUID);
            break;
          case "api-pn-security-apiKey-catpcha":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.security.x_api_key),
            );
            headers = headers.append("authorizationToken", ngCaptcha);
            headers = headers.append("request-uuid", requestUUID);
            break;
          case "api-pn-shared-apiKey-token":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("Authorization", `Bearer ${token}`);
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.shared.x_api_key),
            );
            headers = headers.append("request-uuid", requestUUID);
            break;
          case "api-web-tag-apiKey-token":
            headers = headers.append("FechaHoraServidor", new Date().toISOString());
            headers = headers.append("CodigoCorrelacion", environment.intermediatorId);
            headers = headers.append("Solicitante", `${environment.intermediatorId}_${solId}`);
            headers = headers.append("ctype", "backoffice");
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("Authorization", `Bearer ${token}`);
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.webTag.x_api_key),
            );
            break;
          case "api-web-tag-apiKey-shipping-token":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("Authorization", `Bearer ${token}`);
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.webTag.x_api_key),
            );
            headers = headers.append("request-uuid", requestUUID);
            break;
          case "api-gateway-operators":
            headers = headers.append("FechaHoraServidor", new Date().toISOString());
            headers = headers.append("CodigoCorrelacion", environment.intermediatorId);
            headers = headers.append("Solicitante", `${environment.intermediatorId}_${solId}`);
            break;
          case "api-gateway-proof-a5":
            headers = headers.append("FechaHoraServidor", new Date().toISOString());
            headers = headers.append("CodigoCorrelacion", environment.intermediatorId);
            headers = headers.append("Solicitante", `${environment.intermediatorId}_${solId}`);
            headers = headers.append("Ocp-Apim-Subscription-Key", `${environment.subscriptionKey}`);
            headers = headers.append("Authorization", `Bearer ${token}`);
            break;
          case "api-gateway-commons-transactions":
            headers = headers.append("FechaHoraServidor", new Date().toISOString());
            headers = headers.append("CodigoCorrelacion", environment.intermediatorId);
            headers = headers.append("Solicitante", `${environment.intermediatorId}_${solId}`);
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.commons.x_api_key),
            );
            params = params.append("ngsw-bypass", "true");
            break;
          case "api-pj-report-apiKey-token":
            headers = headers.delete("request-date");
            headers = headers.delete("request-uuid");
            headers = headers.append("Authorization", `Bearer ${token}`);
            headers = headers.append("x-api-key", environment.api_key.reportRequest.x_api_key);
            headers = headers.delete("ctype");
            break;
          case "api-pj-report-apiKey":
            headers = headers.delete("request-date");
            headers = headers.delete("request-uuid");
            headers = headers.append("x-api-key", environment.api_key.reportRequest.x_api_key);
            headers = headers.delete("ctype");
            break;
          case "api-commons-transaction-apiKey":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.commons.x_api_key),
            );
            headers = headers.append("request-uuid", requestUUID);
            break;
          case "api-gateway-priv":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.priv.x_api_key),
            );
            headers = headers.append(
              "request-uuid",
              this.aesEncryptDecryptService.decrypt(environment.api_key.priv.request_uuid),
            );
            break;
          case "api-gateway-vehicles-v2":
            headers = headers.append("Authorization", `Bearer ${token}`);
            break;
          case "api-gateway-vehicles-key":
            headers = headers.append("request-date", new Date().toDateString());
            headers = headers.append("ctype", "pn");
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.tollPublic.x_api_key),
            );
            headers = headers.append(
              "request-uuid",
              this.aesEncryptDecryptService.decrypt(environment.api_key.tollPublic.request_uuid),
            );
            break;
          case "api-toll-services":
            headers = headers.append(
              "x-api-key",
              this.aesEncryptDecryptService.decrypt(environment.api_key.tollServices.x_api_key),
            );
            headers = headers.delete("ctype");
            headers = headers.delete("request-uuid");
            break;
          case "api-token":
            headers = headers.delete("ctype");
            headers = headers.delete("request-uuid");
            headers = headers.append("Authorization", `Bearer ${token}`);
            break;
        }
      }
    } catch (error) {}
    const authReq = req.clone({ headers, params });
    return next.handle(authReq).toPromise();
  }
}
