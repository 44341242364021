import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./authentication.service";
import { ReCaptchaV3Service } from "./recaptcha-v3.service";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class CommonsService {
  timePause: number = 2000;
  /**
   * Constructor
   *
   * @param authenticationService AuthenticationService
   * @param router Router
   * @param http Http
   * @param vehiclesFacade VehiclesFacade
   * @param tagFacade TagFacade
   * @param activationTagService ActivationTagService
   */
  constructor(
    private router: Router,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authenticationService: AuthenticationService,
  ) {}
  /**
   * Logout Method
   */
  async logOut(flag = true, callService = true): Promise<any> {
    if (callService) {
      await this.authenticationService.signOut();
    }
    localStorage.clear();
    if (flag) {
      this.router.navigate(["signin", "login"]);
    }
  }
  /**
   * get Documents to Download
   *
   * @param urls urls
   */
  async getDocumentDownload(urls: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    try {
      const name = urls.split(".com/")[1];
      const resp: any = await this.http
        .get(urls, {
          headers,
          responseType: "blob" as "json",
        })
        .toPromise();
      const blob = new Blob([resp], {
        type: "application/pdf;charset=utf-8",
      });
      saveAs(blob, name);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Generate promise for each url
   */
  async generatorPromiseDownload(urls: string[]) {
    let documentsDownloadCopy = JSON.parse(JSON.stringify(urls));
    const documents = [];
    documents.push(
      new Promise((resolve, reject) => {
        resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
      }),
    );
    if (urls.length > 1) {
      documentsDownloadCopy = documentsDownloadCopy.splice(1);
      documents.push(
        new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
          }, 2000);
        }),
      );
    }
    const resolvePromises = await Promise.all(documents);

    return this.compareValues(resolvePromises);
  }

  /**
   * Compare answers to all promises
   */
  compareValues(downloads: boolean[]) {
    let index: number = 0;
    for (const download of downloads) {
      /* eslint no-unused-expressions: "off"*/
      /* eslint @typescript-eslint/no-unused-expressions: "off"*/
      download === false ? (index = index - 1) : (index = index + 1);
    }
    return index === -downloads.length ? false : true;
  }

  /**
   * Redirect to principal dashboard administration
   */
  redirectToPages(): void {
    this.router.navigate(["/pages"]);
  }
  /**
   * Navigate ti url
   *
   * @param url
   */
  navigate(urlSegment: string): void {
    this.router.navigate([urlSegment]);
  }
  /**
   * get Token Information
   */
  async getTokenInfo(): Promise<any> {
    return this.authenticationService.getUserData();
  }
  /**
   * get Ng Chapcha Info
   *
   * @param attemps intentos
   */
  async getTokenNgCaptcha(attemps: number = 1): Promise<any> {
    if (environment.production) {
      localStorage.setItem(
        "captcha",
        "03AFcWeA4dYM6CF59ec6T8KzlovB367Mod8_Io8590p_37AODC3pskXzFGHKAyYFVlqA_ESi_7hFK2sxrU_sZl7LCtLzy1rX5M-dnJrVc5sVfl6sFva24XqMLECNW7j5emkld4o6CvyQxAcfMxyosuizI240VMuGayvYFWpKaK7u0SY8RhrmA5-ZlnrsCn-zEHbHEPgnLwt9SXpiGquS12H9gxKx6vfzuLSs95ZKdZ2g0UWrLSPQUK86P2EfkVal4jgOa--v_86Y9ax8wE3HDP7K-c0dFA078F6IyPtd89d_bnkG0w_F53R7KwxyK2Ph91h4tJbcNXm57ZC-OOXsBHaT3ICwdGVw-J_STZfp7ejmCYgHxJdkKZo932TaFPj2Bwpbumecdq7ZzGhgR-Q_yjAa1AJvuMCGa2t7GPwKF6gaSItHXdE5jj08NREkFWMAGlPolacjl1g7q8dwZHzueGd8uHGI2rpsSSxOW3bRpRshwSgDvwQm_rddxf1H_XpA4p-wzb_tbUklmMbrwSZhQYgL7XW7hF_sgQb3MQqFbvJNZCAoYAFhJxf6HancVjdYzLdJ7_hovFm0ceAU-PpXAsyBxgp3GH41znYIbJDwNGNL8ifLjVBypy9_LIUnWydW-GQDBz_YH6G2o4TyUp5tkfMtbB9xjhVeupbv88QEDbvaE32AfE6pVFxM__6S5LOG_LvlqdY-FhmZCc9AEamQ88mlkkVvG780Ah3LgogtfDpTUveEZhpOzVjXtAzgJJGu4k6E9gevuO3LUDC9aB8l1EcdApkr0HKH96EJl11F8Ry0DLSeFF0NU5cxyNGGmrrsLARXpJMS9Dxn1KcOi1JU5JwD_CDE-d8edHo4hdeWfThKa7XePWtw8rsfKhlRLXWm1Gzb4g9n-tIKMKVcdxJftQROTyvK1m1eQStKvQj0h9cyO_O0gHhcZHDbYOeirkGPmegUMwWGUM1Vs4g0cbs-cfiHRhq6xg3o7t6Ezbje2cTpS6WSx8ers8y2E7E8kAgA7aoloEbXGrILAhlMgh5CNWzGjv_-yZJOR629BLkY1lnkObYjVaMYmJ6qox4vEh6A5AnZs_CMkljUcNKXEjmZcIgsbMv2xRylSuBSuK0DFZWxXtS4TCs1QEKIWjt2wjWEajpN7EX9vJSv03lhu90e7e16WLW2fYSt21bs2i20GpFsA-lqaRFZclWglFclVlBs620Eo0nWZ98Cb4WyF0hJboIJ1d1kjlKpAWIzd5tt_DSevO55Knn3ETo91NKNBJJ7D4K82S0BXULaWl2VyDzUZMMCejP954NiXW-jztguklM7O7CopDG6Qi2LTr19mgfyg4b9ZGHCPz4GWsQPLgXtW4AKQI_26oZNdB0gYXZCKdkfIpLCLhte5VSlLPR-LNj4IBwL_LQtLjPS799VSbVCUDHnOlh1a7K1N5fsa8LnGBIbm9Rog9Uvj6ZsDP4YYLiJa0afpXsrea6fM1zvET3X10PGwpttk6DYTNSMDs20ZoeyUvJ2tyIgI5g6bGf5lBTu1BDr4oaDeOTUXic9TlnLEiFDDoOe2W68fDAAXMushF4CxPdmvpkVnDqvlYhPWTPipZiTE1b0dVae4X5jOzIfnckTe-B2jAxWWheQnoKt0sSzolCCcXkmOR0GEEi7R7Vru321zcJq_BbwLsZPyljDFEme6JQ49W7kS9oYLQ7_ID82qjenBazCmAYVFFkrimZ5tM3abdrc-btJXo9VfybtZJNdO7cbv2TEd5cyVPAuOsMbbc9V-_rKSUxLjaGaAEPsro5Uuff4L3_5Xn1GoS6UlvIiJMi8pcC0kljw2hwqb4ewi8HqnwRERiolFt66sFe1JmxLJI0nmkhFRprxh-CpO7bVMbEsuSBNVWAsaqxX6U4-IsVXtytHorGYiY7Ev5lfRKsUF80x9vbD25Y8ei2UGgC2-bZYwODLb8fQ",
      );
    } else {
      let token = localStorage.getItem("captcha");
      if (attemps < 5) {
        try {
          token = await this.recaptchaV3Service.execute("importantAction").pipe(take(1)).toPromise();
          localStorage.setItem("captcha", token);
        } catch (error) {
          await this.getTokenNgCaptcha(attemps + 1);
        }
      }
    }
    return;
  }
  /**
   * getAccountId from storage service
   */
  async getAccountIdRegisteredSingup(): Promise<any> {
    return localStorage.getItem("accountId");
  }

  validationVerificationDigit(nitAdjusted: string): string {
    const lengthAdjusted = 15 - nitAdjusted.length;
    for (let index = 0; index < lengthAdjusted; index++) {
      nitAdjusted = "0" + nitAdjusted;
    }
    let digito: number = 0;
    const multiplicacion = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3];
    for (let index = 0; index < nitAdjusted.length; index++) {
      digito = digito + Number(nitAdjusted.substring(index, index + 1)) * multiplicacion[index];
    }
    let residue = digito % 11;
    switch (residue) {
      case 0:
        residue = 0;
        break;
      case 1:
        residue = 1;
        break;
      default:
        residue = 11 - residue;
        break;
    }
    return residue.toString();
  }
}
